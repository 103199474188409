import { Feature } from 'geojson';

import { PropertyTypeEnum } from '../../cerberus';
import { DateStr } from '../../Date.types';
import { NestedObjectPaths } from '../../json-patch/JsonPatch.types';
import { ListingStatusNormalized } from '../../property-graph';
import { PropertyStateFields } from '../../property-state';
import { CompFields } from '../../property-state/CompFields.types';

import { SimilarityLevel } from '.';

export const FILTER_MATCH_SUBJECT = 'FILTER_MATCH_SUBJECT';
export type MatchSubject = typeof FILTER_MATCH_SUBJECT;
export type NumberRange = [number | null, number | null];

export interface FilterError {
  field: 'absoluteValue' | 'relativeValue';
  message: string;
}

interface FilterBase<F extends keyof CompFiltersAll> {
  field: F;
  error?: FilterError;
}

export interface BooleanFilterValues<F extends keyof CompFiltersAll>
  extends FilterBase<F> {
  absoluteValue: boolean | null;
  relativeValue: boolean | MatchSubject | null;
}

export interface NumberRangeFilterValues<F extends keyof CompFiltersAll>
  extends FilterBase<F> {
  absoluteValue: NumberRange | null;
  relativeValue: NumberRange | null;
}

export interface DateAgoFilterRangeValues<F extends keyof CompFiltersAll>
  extends FilterBase<F> {
  absoluteValue: [DateStr, DateStr] | null;
  relativeValue: number | null;
}

export interface MatchRangeFilterValues<F extends keyof CompFiltersAll>
  extends FilterBase<F> {
  relativeValue: NumberRange | MatchSubject | null;
  absoluteValue: NumberRange | null;
}

export interface CompFiltersAll {
  [PropertyStateFields.bathrooms]: NumberRangeFilterValues<PropertyStateFields.bathrooms>;
  [PropertyStateFields.basementHas]: BooleanFilterValues<PropertyStateFields.basementHas>;
  [PropertyStateFields.bedrooms]: NumberRangeFilterValues<PropertyStateFields.bedrooms>;
  [PropertyStateFields.lastCloseDate]: DateAgoFilterRangeValues<PropertyStateFields.lastCloseDate>;
  [PropertyStateFields.lastCloseDateRental]: DateAgoFilterRangeValues<PropertyStateFields.lastCloseDateRental>;
  [PropertyStateFields.lastClosePrice]: NumberRangeFilterValues<PropertyStateFields.lastClosePrice>;
  [PropertyStateFields.lastClosePriceRental]: NumberRangeFilterValues<PropertyStateFields.lastClosePriceRental>;
  [PropertyStateFields.yearBuilt]: NumberRangeFilterValues<PropertyStateFields.yearBuilt>;
  [PropertyStateFields.currentListDate]: DateAgoFilterRangeValues<PropertyStateFields.currentListDate>;
  [PropertyStateFields.currentListDateRental]: DateAgoFilterRangeValues<PropertyStateFields.currentListDateRental>;
  [PropertyStateFields.pool]: BooleanFilterValues<PropertyStateFields.pool>;
  [PropertyStateFields.currentListingPrice]: NumberRangeFilterValues<PropertyStateFields.currentListingPrice>;
  [PropertyStateFields.currentListingPriceRental]: NumberRangeFilterValues<PropertyStateFields.currentListingPriceRental>;
  [PropertyStateFields.livingArea]: NumberRangeFilterValues<PropertyStateFields.livingArea>;
  [PropertyStateFields.garageSpaces]: MatchRangeFilterValues<PropertyStateFields.garageSpaces>;
  [PropertyStateFields.lotSize]: NumberRangeFilterValues<PropertyStateFields.lotSize>;
  [PropertyStateFields.stories]: MatchRangeFilterValues<PropertyStateFields.stories>;
  [PropertyStateFields.propertyType]: FilterBase<PropertyStateFields.propertyType> & {
    absoluteValue: PropertyTypeEnum[] | null;
    relativeValue: PropertyTypeEnum[] | MatchSubject | null;
  };
  [PropertyStateFields.currentStatus]: FilterBase<PropertyStateFields.currentStatus> & {
    absoluteValue: ListingStatusNormalized[] | null;
    relativeValue: ListingStatusNormalized[] | MatchSubject | null;
  };
  [PropertyStateFields.currentStatusRental]: FilterBase<PropertyStateFields.currentStatusRental> & {
    absoluteValue: ListingStatusNormalized[] | null;
    relativeValue: ListingStatusNormalized[] | MatchSubject | null;
  };
  [CompFields.similarity]: FilterBase<CompFields.similarity> & {
    absoluteValue: SimilarityLevel[] | null;
    relativeValue: SimilarityLevel[] | null;
  };
  [CompFields.distance]: FilterBase<CompFields.distance> & {
    absoluteValue: Feature | null;
    relativeValue: number | null;
  };
  [PropertyStateFields.currentValueRental]: NumberRangeFilterValues<PropertyStateFields.currentValueRental>;
}

export type CompFilters = Partial<CompFiltersAll>;
export type CompsFiltersKeys = keyof CompFiltersAll;

export interface CompsFiltersSchema {
  appliedFilterSetId?: string;
  filters?: CompFilters;
  sort?: {
    field: PropertyStateFields | CompFields;
    order: 'ASC' | 'DESC';
  } | null;
}

export type FilterTypeNumberRange =
  | CompFiltersAll[PropertyStateFields.bathrooms]
  | CompFiltersAll[PropertyStateFields.bedrooms]
  | CompFiltersAll[PropertyStateFields.yearBuilt]
  | CompFiltersAll[PropertyStateFields.lastClosePrice]
  | CompFiltersAll[PropertyStateFields.lastClosePriceRental]
  | CompFiltersAll[PropertyStateFields.currentListingPrice]
  | CompFiltersAll[PropertyStateFields.currentListingPriceRental]
  | CompFiltersAll[PropertyStateFields.livingArea]
  | CompFiltersAll[PropertyStateFields.lotSize];

export type CompsFiltersPaths =
  `/data/${NestedObjectPaths<CompsFiltersSchema>}`;

// TODO: support keyword search filtering in report api, right now it is only a FE concept
export type FilterFieldsType =
  | PropertyStateFields
  | CompFields
  | 'listingDetailsSale.remarks'
  | 'listingDetailsRental.remarks';

export type FilterFieldsListType = FilterFieldsType[];
