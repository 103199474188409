import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { useCopyToClipboard } from '@hcs/hooks';
import { OptionalEngagementProps } from '@hcs/types';

import { Button } from '../../buttons/Button';

import styles from './CopyInput.module.css';

export interface CopyInputProps {
  dataHcName: string;
  copyEngagement?: OptionalEngagementProps;
  value: string;
  className?: string;
  buttonLabel?: string;
  successLabel?: string;
  onCopy?: VoidFunction;
  onSelectAll?: (e: MouseEvent<HTMLInputElement>) => void;
}

export const CopyInput = ({
  dataHcName,
  value,
  className,
  onCopy,
  onSelectAll,
  copyEngagement,
  buttonLabel = 'Copy Link',
  successLabel = 'Link Copied',
}: CopyInputProps) => {
  const copyToClipboard = useCopyToClipboard();
  const ref = useRef<HTMLInputElement>(null);
  const handleSelectAll = (e: MouseEvent<HTMLInputElement>) => {
    if (ref.current) {
      ref.current.setSelectionRange(0, value.length);
    }
    onSelectAll?.(e);
  };
  const [clickedCopy, setClickedCopy] = useState<boolean>(false);

  useEffect(() => {
    let hideTimeout: NodeJS.Timeout;
    if (clickedCopy) {
      hideTimeout = setTimeout(() => {
        setClickedCopy(false);
      }, 3000);
    }
    return () => clearTimeout(hideTimeout);
  }, [clickedCopy]);

  const handleCopy = () => {
    setClickedCopy(true);
    copyToClipboard(value || '');
    onCopy?.();
  };
  return (
    <div className={classNames(styles.CopyInput, className)}>
      <div className={styles.InputContainer}>
        <input
          ref={ref}
          type="text"
          data-hc-name={`${dataHcName}-input`}
          className={styles.Input}
          value={value || ''}
          onClick={handleSelectAll}
          // Repress React warning from absence of readOnly attr, needed to
          // allow mobile users to choose "Select All" if desired
          onChange={() => {
            return null;
          }}
        />
        <div
          data-hc-name={`${dataHcName}-success-label`}
          className={classNames(styles.Clicked, {
            [styles.Overlay]: clickedCopy,
          })}
          role={clickedCopy ? 'status' : undefined}
        >
          {successLabel}
        </div>
      </div>
      <Button
        {...copyEngagement}
        onClick={handleCopy}
        dataHcName={`${dataHcName}-copy-button`}
      >
        {buttonLabel}
      </Button>
    </div>
  );
};
