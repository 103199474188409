import { Capability, FeatureFlags, FeatureFlagsData } from '@hcs/types';

export const checkHasCapability = (
  capabilityKey: string,
  userCapabilities: Capability[],
  featureFlags: FeatureFlagsData | undefined
) => {
  let hasCapability: boolean = userCapabilities.some(
    (capability) => capability.key === capabilityKey
  );
  if (!hasCapability) {
    if (capabilityKey === 'appraisal-compare-report') {
      hasCapability = !!featureFlags?.[FeatureFlags.AppraisalComparisonReports];
    }

    /**
     * Handle Migration Case:
     * Offer Now - Feature Flag <> Capability
     */
    if (capabilityKey === 'offer-now') {
      hasCapability = !!featureFlags?.[FeatureFlags.OfferNowAvailable];
    }
    /**
     * Migration Case:
     * Effective Date Reports - Feature Flag <> Capability
     */
    if (capabilityKey === 'effective-date-dynamic-report') {
      hasCapability = !!featureFlags?.[FeatureFlags.EffectiveDateReports];
    }
  }
  return hasCapability;
};
