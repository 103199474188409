import { useEffect } from 'react';

import { GlobalEngagementEventFields } from '@hcs/types';

import { useGlobalEngagementEventSlice } from './useGlobalEngagementEventSlice';

export const useUtmCodeEngagementEventData = () => {
  const {
    actions: { registerGlobalEventData },
  } = useGlobalEngagementEventSlice();
  const searchParams = new URLSearchParams(window.location.search);
  const urlParamUtmCampaign = searchParams.get('utm_campaign');
  const urlParamUtmContent = searchParams.get('utm_content');
  const urlParamUtmMedium = searchParams.get('utm_medium');
  const urlParamUtmSource = searchParams.get('utm_source');
  useEffect(() => {
    const utmCodes: Partial<GlobalEngagementEventFields> = {};
    if (urlParamUtmCampaign) {
      utmCodes.utmCampaign = urlParamUtmCampaign;
    }
    if (urlParamUtmContent) {
      utmCodes.utmContent = urlParamUtmContent;
    }
    if (urlParamUtmMedium) {
      utmCodes.utmMedium = urlParamUtmMedium;
    }
    if (urlParamUtmSource) {
      utmCodes.utmSource = urlParamUtmSource;
    }
    if (Object.keys(utmCodes).length > 0) {
      registerGlobalEventData(utmCodes);
    }
  }, [
    urlParamUtmCampaign,
    urlParamUtmContent,
    urlParamUtmMedium,
    urlParamUtmSource,
    registerGlobalEventData,
  ]);
};
