import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import * as yup from 'yup';

import {
  ACCOUNT_FIELD_CONFIGS,
  AccountFieldInput,
  CARARYAI_FIELD_CONFIGS,
} from '@hcs/auth';
import { Anchor, Button, Card, Checkbox, FormError } from '@hcs/design-system';
import { APP_CONFIG_CANARYAI } from '@hcs/hc-products';
import {
  AccountFields,
  MeaningfulEventTypes,
  SignUpFormFields,
  SignUpPayload,
} from '@hcs/types';
import { logException } from '@hcs/utils';

import { useSelfServiceSignUp } from '../../hooks/useSelfServiceSignup';

import styles from './SelfServiceSignUp.module.css';

const yupSchema = yup.object().shape({
  [AccountFields.FirstName]:
    ACCOUNT_FIELD_CONFIGS[AccountFields.FirstName].validator,
  [AccountFields.LastName]:
    ACCOUNT_FIELD_CONFIGS[AccountFields.LastName].validator,
  [AccountFields.CompanyName]:
    ACCOUNT_FIELD_CONFIGS[AccountFields.CompanyName].validator,
  [AccountFields.Phone]: ACCOUNT_FIELD_CONFIGS[AccountFields.Phone].validator,
  [AccountFields.Email]: ACCOUNT_FIELD_CONFIGS[AccountFields.Email].validator,
  [AccountFields.Password]:
    ACCOUNT_FIELD_CONFIGS[AccountFields.Password].validator,
  [AccountFields.NonLicensedAgreement]:
    ACCOUNT_FIELD_CONFIGS[AccountFields.NonLicensedAgreement].validator,
});

export interface SelfServiceSignUpProps {
  className?: string;
  appSlug?: string;
  navigateToLogin: VoidFunction;
  onSuccess?: VoidFunction;
}

type ErrorResponse = AxiosError<{
  status: string;
}>;

const dataHcName = 'self-service-sign-up';

export const SelfServiceSignUp = ({
  className,
  appSlug,
  navigateToLogin,
  onSuccess,
}: SelfServiceSignUpProps) => {
  const form = useForm<SignUpFormFields>({
    resolver: yupResolver(yupSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const accountFieldConfigs =
    appSlug && appSlug === APP_CONFIG_CANARYAI.appSlug
      ? CARARYAI_FIELD_CONFIGS
      : ACCOUNT_FIELD_CONFIGS;

  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const options = {
    onError(error: ErrorResponse) {
      // 400 is usually password not meeting requirements or something that isn't a problem to address
      if (error.response?.status !== 400) {
        logException(
          new Error(`Sign Up failed: ${error.response?.data.status}`)
        );
      }
      // ignoring the account api error messages as they are purposefully vague
      setErrorMessage('Sign Up Failed');
    },
    onSuccess() {
      form.reset();
      onSuccess?.();
    },
  };
  const { mutate: selfServiceMutate, isLoading } =
    useSelfServiceSignUp(options);

  const handleSubmit = (data: SignUpFormFields) => {
    // We are currently gate-keeping new Self-Service signups with this NonLicensedAgreement field.
    // HouseCanary is not storing this value - no need to send to backend.
    // Reference DP-504 for more details.
    const { nonLicensedAgreement, ...rest } = data;
    const signUpPayload: SignUpPayload = {
      ...rest,
    };
    selfServiceMutate(signUpPayload);
  };
  return (
    <Card
      dataHcEventSection="Self Serve Sign Up Form"
      dataHcName={dataHcName}
      className={className}
    >
      {errorMessage && (
        <FormError
          // Hardcoded to prevent current automation from breaking
          dataHcName={`${dataHcName}-errors`}
          value={<div>{errorMessage}</div>}
        />
      )}
      <FormProvider {...form}>
        <form
          className={styles.Form}
          onSubmit={form.handleSubmit(handleSubmit)}
        >
          <Controller
            name={AccountFields.FirstName}
            control={form.control}
            render={({ field }) => (
              <AccountFieldInput
                {...field}
                className={styles.AccountFieldInput}
                name={AccountFields.FirstName}
                error={form.formState.errors[field.name]?.message}
              />
            )}
          />
          <Controller
            name={AccountFields.LastName}
            control={form.control}
            render={({ field }) => (
              <AccountFieldInput
                {...field}
                className={styles.AccountFieldInput}
                name={AccountFields.LastName}
                error={form.formState.errors[field.name]?.message}
              />
            )}
          />
          <Controller
            name={AccountFields.CompanyName}
            control={form.control}
            render={({ field }) => (
              <AccountFieldInput
                {...field}
                className={styles.AccountFieldInput}
                name={AccountFields.CompanyName}
                error={form.formState.errors[field.name]?.message}
              />
            )}
          />
          <Controller
            name={AccountFields.Phone}
            control={form.control}
            render={({ field }) => (
              <AccountFieldInput
                {...field}
                className={styles.AccountFieldInput}
                name={AccountFields.Phone}
                error={form.formState.errors[field.name]?.message}
              />
            )}
          />
          <Controller
            name={AccountFields.Email}
            control={form.control}
            render={({ field }) => (
              <AccountFieldInput
                {...field}
                className={styles.AccountFieldInput}
                onChange={(v) => {
                  setErrorMessage(undefined);
                  field.onChange(v);
                }}
                name={AccountFields.Email}
                error={form.formState.errors[field.name]?.message}
              />
            )}
          />
          <Controller
            name={AccountFields.Password}
            control={form.control}
            render={({ field }) => (
              <AccountFieldInput
                {...field}
                className={styles.AccountFieldInput}
                name={AccountFields.Password}
                error={form.formState.errors[field.name]?.message}
              />
            )}
          />
          <Controller
            name={AccountFields.NonLicensedAgreement}
            control={form.control}
            render={({ field }) => (
              <>
                <Checkbox
                  {...field}
                  dataHcName={`account-field-input-${AccountFields.NonLicensedAgreement}`}
                  label={`${
                    accountFieldConfigs[AccountFields.NonLicensedAgreement]
                      .label
                  } *`}
                  detail={
                    accountFieldConfigs[AccountFields.NonLicensedAgreement]
                      .description
                  }
                  checked={field.value || false}
                  onChange={(v) => {
                    field.onChange(v);
                  }}
                />
                {form.formState.errors[field.name] && (
                  <div
                    data-hc-name={`account-field-input-${AccountFields.NonLicensedAgreement}-error`}
                    className={styles.Error}
                  >
                    {form.formState.errors[field.name]?.message}
                  </div>
                )}
              </>
            )}
          />
          <div>
            Already Have an Account?{' '}
            <Anchor
              dataHcName={`${dataHcName}-login`}
              onClick={navigateToLogin}
            >
              Login
            </Anchor>
          </div>
          <Button
            dataHcName={`${dataHcName}-submit-button`}
            dataHcEventName="Self Serve Sign Up Submit"
            dataHcEventType={MeaningfulEventTypes.Goal}
            label="Sign Up"
            disabled={isLoading || !!errorMessage}
          />
        </form>
      </FormProvider>
    </Card>
  );
};
