import { useMemo } from 'react';

import { getSessionId, useAccount } from '@hcs/auth';
import { EngagementEvent, EngagementEventData } from '@hcs/types';

import { useEngagementTrackingEventDataSlice } from './useEngagementTrackingEventDataSlice';
import {
  useDeviceEngagementEventData,
  useUrlSearchParamEngagementEventData,
} from '.';

// Returns a function to build engagement event data
// with programmatically populated fields
export const useBuildEngagementEventData = () => {
  const { data: account } = useAccount();
  const urlSearchParams = useUrlSearchParamEngagementEventData();
  const device = useDeviceEngagementEventData();
  // Add any additional global data fields that have been registered to the rtk slice
  const { state: registeredEventDataState } =
    useEngagementTrackingEventDataSlice();
  const registeredEventData = useMemo(() => {
    let data: Partial<EngagementEventData> = {};

    for (const eventDataId in registeredEventDataState) {
      const eData = registeredEventDataState[eventDataId]?.eventData;
      if (eData) {
        data = {
          ...data,
          ...eData,
        };
      }
    }
    return data;
  }, [registeredEventDataState]);
  const subscriptionType = account?.subscriptionType;
  const buildEngagementEventData = (
    eventData: EngagementEvent['eventData']
  ): EngagementEventData | undefined => {
    return {
      ...eventData,
      ...registeredEventData,
      device,
      urlSearchParams,
      sessionId: getSessionId(),
      subscriptionType,
    };
  };
  return {
    buildEngagementEventData,
  };
};
