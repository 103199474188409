import { ReactNode } from 'react';
import * as yup from 'yup';

import { AppSlugs } from '../auth';
import { InputProps } from '../design-system';

export enum AccountFields {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Password = 'password',
  CurrentPassword = 'currentPassword',
  NewPassword = 'newPassword',
  ConfirmPassword = 'confirmPassword',
  StreetAddress = 'streetAddress',
  City = 'city',
  State = 'state',
  Zipcode = 'zipCode',
  Phone = 'phone',
  CompanyName = 'companyName',
  NonLicensedAgreement = 'nonLicensedAgreement',
}

export interface AccountFieldConfig {
  label: string;
  description?: ReactNode;
  field: string;
  inputProps?: Omit<InputProps, 'dataHcName' | 'value' | 'onChange'>;
  validator: yup.AnySchema;
}

export interface SignUpFormFields {
  [AccountFields.FirstName]: string;
  [AccountFields.LastName]: string;
  [AccountFields.CompanyName]?: string | null;
  [AccountFields.Phone]?: string | null;
  [AccountFields.Email]: string;
  [AccountFields.Password]: string;
  [AccountFields.NonLicensedAgreement]?: boolean | null;
}

export interface SignUpPayload extends SignUpFormFields {
  code?: string;
  application?: AppSlugs;
  confirmUrl?: string;
}

export interface ForgotPasswordFormFields {
  [AccountFields.Email]: string;
}

export interface ResetPasswordFormFields {
  [AccountFields.NewPassword]: string;
  [AccountFields.ConfirmPassword]: string;
}

export interface ResetPasswordPayload {
  token: string;
  [AccountFields.NewPassword]: string;
}

export type AccountFormPayloads = SignUpFormFields | ForgotPasswordFormFields;

export interface ConfirmUserPayload {
  token: string;
}

export type ResendConfirmUserPayload = {
  confirmUrl: string;
};
