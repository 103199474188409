import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EngagementTrackingDevToolSliceState } from '@hcs/types';

const initialState: EngagementTrackingDevToolSliceState = {
  logEvents: false,
};
export const engagementTrackingDevToolSlice = createSlice({
  name: 'devTool',
  initialState,
  reducers: {
    setLogEvents(
      state,
      { payload }: PayloadAction<false | 'all' | 'meaningful'>
    ) {
      state.logEvents = payload;
    },
  },
});
