import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { EventStreamContentType } from '@microsoft/fetch-event-source';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { AuthApi, useAccessToken } from '@hcs/auth';
import { hcsConsole } from '@hcs/console';
import { useIsPageVisible, usePrevious } from '@hcs/hooks';
import { ACCOUNT_QUERY_KEY } from '@hcs/http-clients';
import { useToastSlice } from '@hcs/toast';
import { ReportBase, ReportEvent, ReportId } from '@hcs/types';
import { Account } from '@hcs/types';
import { logException, logWarning } from '@hcs/utils';

import { createReportApiEventSource } from '../api';
import { QUERY_KEY_DOCUMENT_ROLE } from '../hooks';
import { QUERY_KEY_REPORT } from '../hooks/useReport';

const NETWORK_ERROR_NAMES_AND_MESSAGES = [
  'AbortError',
  'Network Error',
  'network error',
  'Failed to fetch',
  'TypeError: Load failed',
  'LoadFailed',
  'Error in input stream',
  'NetworkError when attempting to fetch resource.',
];

type ReportEventMessageListener = (e: ReportEvent) => void;
type ConnectionStatus = 'connected' | 'retrying' | 'disconnected';

class RetriableError extends Error {}
class FatalError extends Error {}
class TokenExpiredError extends Error {}

export const ReportApiEventsContext = createContext({
  subscribeToReportEvents: (params: {
    callbackId: string;
    componentId: string;
    onMessage: ReportEventMessageListener;
  }) => {},
  unsubscribeFromReportEvents: (params: {
    callbackId: string;
    componentId: string;
  }) => {},
  getConnectionStatus: (): ConnectionStatus => 'disconnected',
});

export const ReportApiEventsProvider = ({
  reportId,
  children,
}: {
  reportId: ReportId;
  children: ReactNode;
}) => {
  const queryClient = useQueryClient();
  const {
    actions: { toastClose },
  } = useToastSlice();
  const eventSource = useRef<{
    eventSourceAbortController: AbortController;
    reportId: ReportId;
  } | null>(null);
  const connectionStatus = useRef<ConnectionStatus>('disconnected');

  // Subscribed components keep their callbacks in a global
  // mapping so all components can share a single connection
  const listeners = useRef<{
    onMessage: Map<string, (e: ReportEvent) => void>;
  }>({
    onMessage: new Map(),
  });
  // Keep track of individual components listening to the stream
  const componentsListening = useRef(new Map<string, Map<string, boolean>>());
  const isPageVisible = useIsPageVisible();
  const isPageVisiblePrevious = usePrevious(isPageVisible);
  const { data: accessToken } = useAccessToken();

  const subscribeToReportEvents = useCallback(
    ({
      callbackId,
      componentId,
      onMessage,
    }: {
      callbackId: string;
      componentId: string;
      onMessage: ReportEventMessageListener;
    }) => {
      if (!componentsListening.current.has(callbackId)) {
        componentsListening.current.set(callbackId, new Map<string, boolean>());
      }

      const componentsListeningToCallbackId =
        componentsListening.current.get(callbackId);
      componentsListeningToCallbackId?.set(componentId, true);

      const existingListener = listeners.current.onMessage.get(callbackId);
      if (onMessage !== existingListener) {
        // note that there can only be one subscriber per callbackId. So if multiple components are subscribing, only the last one will have it's callback registered
        listeners.current.onMessage.set(callbackId, onMessage);
      }
    },
    []
  );

  const unsubscribeFromReportEvents = useCallback(
    ({
      callbackId,
      componentId,
    }: {
      callbackId: string;
      componentId: string;
    }) => {
      // since multiple components (i.e. instances of the callbackId) can subscribe, we don't want to clear the callback unless all components have unsubscribed
      const componentsListeningToCallbackId =
        componentsListening.current.get(callbackId);
      componentsListeningToCallbackId?.delete(componentId);
      if (!componentsListeningToCallbackId?.size) {
        // Unsubscribe from global listeners
        listeners.current.onMessage.delete(callbackId);
        componentsListening.current.delete(callbackId);
      }
    },
    []
  );

  /*
   * shouldClearListeners: In some instances we want to clear listeners (like use effect cleanup on unmount)
   * and in some instances we don't (re-connection after hide the window and come back).
   * It's really just based on when those listeners will run their subscribe function again
   */
  const closeEventSource = useCallback(
    ({ shouldClearListeners }: { shouldClearListeners: boolean }) => {
      if (eventSource.current !== null) {
        hcsConsole.log('Report-Api: SSE | disconnected');
        eventSource.current.eventSourceAbortController.abort();
        eventSource.current = null;
        if (shouldClearListeners) {
          listeners.current.onMessage.clear();
        }
      }
      connectionStatus.current = 'disconnected';
    },
    []
  );

  const getConnectionStatus = useCallback(() => {
    return connectionStatus.current;
  }, []);

  const createEventSource = useCallback(
    (reportId: ReportId) => {
      // Need to get the numeric report id when viewing a public report to know if the messages are for the report the user is viewing
      const reportIdNumber =
        typeof reportId === 'number'
          ? reportId
          : queryClient.getQueryData<ReportBase>([QUERY_KEY_REPORT, reportId])
              ?.id;

      const ctrl = new AbortController();
      eventSource.current = {
        eventSourceAbortController: ctrl,
        reportId,
      };
      return createReportApiEventSource({
        reportId,
        config: {
          signal: ctrl.signal,
          // this lib can auto disconnect/reconnect on visibility change with this flag (defaults to false)
          openWhenHidden: false,
          async onopen(response) {
            if (
              response.ok &&
              response.headers.get('content-type') === EventStreamContentType
            ) {
              hcsConsole.log(
                `Report-Api: SSE | connected, Report = ${reportId}`
              );
              connectionStatus.current = 'connected';
              return; // everything's good
            } else if (response.status === 401) {
              // don't want to retry with same headers/token, need new connection
              throw new TokenExpiredError();
            } else if (
              response.status >= 402 &&
              response.status < 500 &&
              response.status !== 429
            ) {
              hcsConsole.logVerbose(
                'Report-Api: SSE |',
                `onOpen response status: ${response.status}, fatal error`
              );
              // other client-side errors are usually non-retriable:
              throw new FatalError();
            } else {
              throw new RetriableError();
            }
          },
          onclose() {
            hcsConsole.logVerbose(
              'Report-Api: SSE |',
              `server closed connection unexpectedly, retrying`
            );
            // if the server closes the connection unexpectedly, retry:
            throw new RetriableError();
          },
          onmessage(msg) {
            // if the server emits an error message, throw an exception
            // so it gets handled by the onerror callback below:
            if (msg.event === 'FatalError') {
              throw new FatalError(msg.data);
            } else if (msg.event === 'ping') {
              // ignore pings, we might not actually need these
              return;
            } else {
              const reportEvent = JSON.parse(msg.data) as ReportEvent;
              hcsConsole.logVerbose('Report-Api: SSE |', reportEvent);
              // if reportIdNumber defined, filter by report id
              if (reportIdNumber) {
                if (reportEvent.report.id === reportIdNumber) {
                  listeners.current.onMessage.forEach((callback) => {
                    callback(reportEvent);
                  });
                }
              } else {
                listeners.current.onMessage.forEach((callback) => {
                  callback(reportEvent);
                });
              }
            }
          },
          onerror(err) {
            if (err instanceof FatalError || err instanceof TokenExpiredError) {
              connectionStatus.current = 'disconnected';
              throw err; // rethrow to stop the operation
            } else if (err instanceof RetriableError) {
              // do nothing to automatically retry. You can also
              // return a specific retry interval here.
              connectionStatus.current = 'retrying';
              return 3000;
            } else {
              connectionStatus.current = 'retrying';
              if (
                !NETWORK_ERROR_NAMES_AND_MESSAGES.includes(err?.name) &&
                !NETWORK_ERROR_NAMES_AND_MESSAGES.includes(err?.message)
              ) {
                logWarning(
                  `ReportApiEvents.context.tsx: retrying after unexpected error: ${err}`
                );
              }
              // network maybe down, retry every 10 seconds
              return 10000;
            }
          },
        },
      });
    },
    [queryClient]
  );

  useEffect(() => {
    const refreshToken = async () => {
      const account = await AuthApi.fetchAccount();
      queryClient.setQueryData<Account>([ACCOUNT_QUERY_KEY], account);
    };

    // if this is for a new report id
    if (reportId !== eventSource.current?.reportId) {
      closeEventSource({ shouldClearListeners: true });
      createEventSource(reportId).catch((err) => {
        if (err instanceof TokenExpiredError) {
          // if 401, attempt to refresh token and reconnect
          hcsConsole.logVerbose(
            'Report-Api: SSE |',
            'Retrying Connection after token refresh'
          );
          try {
            // new token should make this useEffect run again
            refreshToken();
          } catch (e) {
            // axios error on token refresh probably means client is offline
            if (axios.isAxiosError(e)) {
              return;
            } else {
              logException(
                new Error(
                  `ReportApiEvents.context.tsx: error refreshing token: ${e}`
                )
              );
            }
          } finally {
            closeEventSource({ shouldClearListeners: false });
          }
          return;
        }
        logException(
          new Error(
            `ReportApiEvents.context.tsx: catch unhandled error: ${err}`
          )
        );
      });
    }
  }, [createEventSource, closeEventSource, reportId, accessToken, queryClient]);

  useEffect(() => {
    // if openWhenHidden is false, the connection will silently close and reopen when the tab is hidden/shown
    if (isPageVisiblePrevious && !isPageVisible) {
      connectionStatus.current = 'disconnected';
    }
    // if page visible goes from false to true, we will have potentially missed updates that happened when the connection was closed
    // so we should invalidate the report data which will force a refetch of it
    if (isPageVisiblePrevious === false && isPageVisible) {
      // close orphaned "Updating report" toasts
      toastClose();
      queryClient.invalidateQueries([QUERY_KEY_REPORT, reportId]);
      queryClient.invalidateQueries([QUERY_KEY_DOCUMENT_ROLE, reportId]);
      connectionStatus.current = 'connected';
    }
  }, [isPageVisible, isPageVisiblePrevious, queryClient, reportId, toastClose]);

  useEffect(() => {
    // if context becomes unmounted, close connection
    return () => {
      // close orphaned "Updating report" toasts
      toastClose();
      closeEventSource({ shouldClearListeners: true });
    };
  }, [closeEventSource, toastClose]);

  return (
    <ReportApiEventsContext.Provider
      value={{
        subscribeToReportEvents,
        unsubscribeFromReportEvents,
        getConnectionStatus,
      }}
    >
      {children}
    </ReportApiEventsContext.Provider>
  );
};
