import React, { ReactNode } from 'react';
import classNames from 'classnames';

import {
  Button,
  ButtonProps,
} from '../../../controls-and-inputs/buttons/Button';

import styles from './TopLevelBanner.module.css';

export interface TopLevelBannerProps {
  /**
   * Required automation and engagement HTML attribute.
   */
  dataHcName: string;
  dataHcEventSection?: string;
  /**
   * Optional className applied to main HTML element.
   */
  className?: string;
  actionButton?: Omit<ButtonProps, 'dataHcName'>;
  children: ReactNode;
}
export const TopLevelBanner = ({
  dataHcName,
  dataHcEventSection,
  className,
  actionButton,
  children,
}: TopLevelBannerProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      data-hc-event-section={dataHcEventSection}
      className={classNames(styles.TopLevelBanner, className)}
    >
      <div data-hc-name={`${dataHcName}-content`}>{children}</div>
      {actionButton && (
        <Button
          {...actionButton}
          dataHcName={`${dataHcName}-action-button`}
          className={classNames(
            styles.TopLevelBannerButton,
            actionButton.className
          )}
        />
      )}
    </div>
  );
};
