import { useDispatch, useSelector } from 'react-redux';

import {
  EngagementTrackingDevToolSliceState,
  EngagementTrackingPartialAppState,
} from '@hcs/types';
import { mapDispatchToSliceActions } from '@hcs/utils';

import { ENGAGEMENT_TRACKING_REDUCER_KEY } from '../rtk/engagementTracking.reducer';
import { engagementTrackingDevToolSlice } from '../rtk/engagementTrackingDevTool.slice';

export const useEngagementTrackingDevToolSlice = () => {
  const dispatch = useDispatch();
  return {
    state: useSelector<
      EngagementTrackingPartialAppState,
      EngagementTrackingDevToolSliceState
    >(
      (state) =>
        state[ENGAGEMENT_TRACKING_REDUCER_KEY][
          engagementTrackingDevToolSlice.name
        ]
    ),
    actions: mapDispatchToSliceActions(
      dispatch,
      engagementTrackingDevToolSlice
    ),
  };
};
