import { gql } from 'graphql-request';

import { FRAGMENT_LISTING_MLS } from './Mls.graphql';

/** PROPERTY STATE | listingDetails  */
export const FRAGMENT_LISTING_DETAILS_DISCOVERY_IDS = gql`
  fragment ListingDetailsDiscoveryIds on ListingIdcerberusDiscovery {
    hcMlsId
    listingId
  }
`;

export const FRAGMENT_LISTING_DETAILS_METADATA = gql`
  ${FRAGMENT_LISTING_DETAILS_DISCOVERY_IDS}
  fragment ListingDetailsMetadata on Listing {
    entityId
    id {
      discovery {
        ...ListingDetailsDiscoveryIds
      }
    }
    mls {
      nameShort
    }
  }
`;

export const FRAGMENT_AGENT_OFFICE = gql`
  fragment AgentOffice on ListingAgentOfficeHcAgentOffice {
    agentKey
    agentMlsId
    email
    licenseNumber
    name
    office
    officeKey
    officeMlsId
    phone
    type
  }
`;

export const FRAGMENT_LISTING_DETAILS_PREVIEW = gql`
  ${FRAGMENT_LISTING_DETAILS_METADATA}
  ${FRAGMENT_AGENT_OFFICE}
  fragment ListingDetailsPreview on Listing {
    ...ListingDetailsMetadata
    agentOffice {
      hc {
        agentOffice {
          ...AgentOffice
        }
      }
    }
  }
`;

export const FRAGMENT_LISTING_LISTING_DETAILS_MEDIA = gql`
  fragment ListingListingDetailsMedia on ListingListingDetailsHc {
    closeDate
    closePrice
    currentPrice
    currentStatusDate
    listPrice
    listingDate
    mlsName
    rentalListing
    status
  }
`;

// Fragment for requesting listing images and other media
export const FRAGMENT_LISTING_DETAILS_MEDIA = gql`
  ${FRAGMENT_LISTING_DETAILS_METADATA}
  ${FRAGMENT_LISTING_MLS}
  ${FRAGMENT_AGENT_OFFICE}
  ${FRAGMENT_LISTING_LISTING_DETAILS_MEDIA}
  fragment ListingDetailsMedia on Listing {
    ...ListingDetailsMetadata
    mls {
      ...ListingMlsFragment
    }
    agentOffice {
      hc {
        agentOffice {
          ...AgentOffice
        }
      }
    }
    listingDetails {
      hc {
        ...ListingListingDetailsMedia
      }
    }
  }
`;

export const FRAGMENT_LISTING_SALE_TYPE = gql`
  fragment ListingSaleType on ListingListingDetailsHc {
    armsLength
    distressed
    reo
    status
  }
`;

export const FRAGMENT_LISTING_LISTING_DETAILS_CORE = gql`
  ${FRAGMENT_LISTING_SALE_TYPE}
  fragment ListingListingDetailsCore on ListingListingDetailsHc {
    ...ListingSaleType
    remarks {
      type
      value
    }
  }
`;

export const FRAGMENT_LISTING_DETAILS_CORE = gql`
  ${FRAGMENT_LISTING_DETAILS_METADATA}
  ${FRAGMENT_AGENT_OFFICE}
  ${FRAGMENT_LISTING_LISTING_DETAILS_CORE}
  fragment ListingDetailsCore on Listing {
    ...ListingDetailsMetadata
    agentOffice {
      hc {
        agentOffice {
          ...AgentOffice
        }
      }
    }
    listingDetails {
      hc {
        ...ListingListingDetailsCore
      }
    }
  }
`;
