import React, { ReactNode } from 'react';
import { Navigate } from 'react-router';

import { DATA_HC_NAME_PAGE_AUTH_SPINNER, useCheckAppAccess } from '@hcs/auth';
import { LimitedAccess } from '@hcs/auth';
import { useCheckAnyAccessibleApplications } from '@hcs/auth';
import { useUserIsSelfService } from '@hcs/auth';
import { LoadingSpinner } from '@hcs/design-system';
import { APP_CONFIG_PEXP, VIEW_PATHS_PEXP } from '@hcs/hc-products';
import { useSelfServicePaymentMethod } from '@hcs/self-service';
import { AppSlugs } from '@hcs/types';

export const NoAccessibleApplicationsRedirect = ({
  children,
}: {
  children: ReactNode;
}) => {
  const {
    data: hasAccessibleApplication,
    isInitialLoading: hasAccessibleApplicationIsLoading,
  } = useCheckAnyAccessibleApplications();
  const {
    data: userIsSelfService,
    isInitialLoading: userIsSelfServiceIsLoading,
  } = useUserIsSelfService();
  const {
    data: selfServicePaymentMethod,
    isInitialLoading: paymentMethodIsLoading,
  } = useSelfServicePaymentMethod({ enabled: !!userIsSelfService });
  const {
    data: hasMarketInsights,
    isInitialLoading: hasMarketInsightsIsLoading,
  } = useCheckAppAccess(AppSlugs.MarketInsights);

  if (
    hasAccessibleApplicationIsLoading ||
    userIsSelfServiceIsLoading ||
    paymentMethodIsLoading ||
    hasMarketInsightsIsLoading
  ) {
    return <LoadingSpinner dataHcName={DATA_HC_NAME_PAGE_AUTH_SPINNER} />;
    // PEXP self-serve:
    // once a self service user enters their credit credit, we make VR an accessible application
    // but it's possible for a user to signup and confirm without entering their credit card
    // so we direct them to add their credit card in this scenario

    // Market Insights self-serve:
    // MI self-serve users are able to access Market Insights without any accessible applications
    // These do not need to enter credit card until they hit paywall in PEXP app
  } else if (userIsSelfService) {
    if (
      !hasAccessibleApplication &&
      !selfServicePaymentMethod &&
      !hasMarketInsights
    ) {
      return (
        <Navigate
          to={`${APP_CONFIG_PEXP.rootPath}/${VIEW_PATHS_PEXP.SELF_SERVICE_SETUP}`}
          replace
        />
      );
    } else {
      return <>{children}</>;
    }
  } else if (!hasAccessibleApplication) {
    return <LimitedAccess />;
  } else {
    return <>{children}</>;
  }
};
