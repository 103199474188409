import { useAccount } from './useAccount';

// Attempts to get access token from sessionStorage
// Attempts to refresh the token if it is not in sessionStorage already
export const useAccessToken = () => {
  const accountQuery = useAccount();
  return {
    ...accountQuery,
    data: accountQuery.isFetched ? accountQuery.data?.validity.token : undefined
  };
};
