import React from 'react';

import { DropdownMini, Switch } from '@hcs/design-system';
import { HcsDevToolsPortal } from '@hcs/dev-tools';
import { useActiveState } from '@hcs/hooks';

import { useEngagementTrackingDevToolSlice } from '../../hooks/useEngagementTrackingDevToolSlice';

import styles from './EngagementTrackingDevTool.module.css';
const dataHcName = 'engagement-tracking-dev-tool';
const SECTIONS_OUTLINED_CLASSNAME = styles.OutlineEventSections;
export const EngagementTrackingDevTool = () => {
  const { active: sectionsOutlined, setActiveState } = useActiveState();
  const {
    state: { logEvents },
    actions: { setLogEvents },
  } = useEngagementTrackingDevToolSlice();
  return (
    <HcsDevToolsPortal
      groups={[
        {
          dataHcName,
          title: 'Engagement Tracking',
          items: [
            {
              type: 'item',
              item: {
                dataHcName: `${dataHcName}-sections-control`,
                content: (
                  <div className={styles.DevToolRow}>
                    <span>Outline Event Sections</span>
                    <Switch
                      dataHcName={`${dataHcName}-sections-control-toggle`}
                      onChange={(v) => {
                        const body = document.getElementsByTagName('body')[0];
                        if (body) {
                          if (v) {
                            body.classList.add(SECTIONS_OUTLINED_CLASSNAME);
                          } else {
                            body.classList.remove(SECTIONS_OUTLINED_CLASSNAME);
                          }
                        }
                        setActiveState(v);
                      }}
                      value={sectionsOutlined}
                      switchOnOption={{
                        value: true,
                      }}
                      switchOffOption={{
                        value: false,
                      }}
                    />
                  </div>
                ),
              },
            },
            {
              type: 'item',
              item: {
                dataHcName: `${dataHcName}-log-control`,
                largeContent: true,
                content: (
                  <div className={styles.DevToolRow}>
                    <span>Log Events</span>
                    <DropdownMini
                      dataHcName={`${dataHcName}-log-toggle`}
                      value={logEvents}
                      options={[
                        { label: 'Off', value: false },
                        { label: 'Meaningful', value: 'meaningful' },
                        { label: 'All', value: 'all' },
                      ]}
                      onSelect={setLogEvents}
                    />
                  </div>
                ),
              },
            },
          ],
        },
      ]}
    />
  );
};
