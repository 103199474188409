import React from 'react';
import { Link } from 'react-router-dom';

import { UserMenuAvatarButton, useUserIsConfirmed } from '@hcs/auth';
import { UserMenuLogoutButton } from '@hcs/auth';
import { useUserIsOrgAdmin } from '@hcs/auth';
import {
  UserMenu,
  UserMenuButton,
  UserMenuButtonProps,
  UserMenuButtons,
} from '@hcs/design-system';
import {
  OrgSettingsIcon,
  ProfileIcon,
  SupportIcon,
  UsageIcon,
} from '@hcs/design-system';
import {
  APP_CONFIG_ORG_ADMIN,
  APP_CONFIG_USER_ADMIN,
  VIEW_PATHS_USER_ADMIN,
} from '@hcs/hc-products';
import { SUPPORT_URL } from '@hcs/urls';

import styles from './SolutionsPlatformUserMenu.module.css';

export const USER_ADMIN_ROOT_PATH = APP_CONFIG_USER_ADMIN.rootPath;
export const ORG_ADMIN_ROOT_PATH = APP_CONFIG_ORG_ADMIN.rootPath;

const dataHcName = 'global-user-menu';
export interface SolutionsPlatformUserMenuProps {
  buttonGroups?: UserMenuButtonProps[][];
  triggerClassName?: string;
  onLogout?: VoidFunction;
}
export const SolutionsPlatformUserMenu = ({
  buttonGroups,
  triggerClassName,
  onLogout,
}: SolutionsPlatformUserMenuProps) => {
  const { data: isOrgAdmin } = useUserIsOrgAdmin();
  const { data: isUserConfirmed, isInitialLoading: isUserConfirmedIsLoading } =
    useUserIsConfirmed();
  if (!isUserConfirmed || isUserConfirmedIsLoading) {
    return null;
  }
  return (
    <UserMenu
      triggerClassName={triggerClassName}
      content={
        <>
          <Link
            data-hc-name={`${dataHcName}-avatar-link`}
            to={USER_ADMIN_ROOT_PATH}
          >
            <UserMenuAvatarButton />
          </Link>
          <UserMenuButtons dataHcName={`${dataHcName}-usage-buttons`}>
            <Link
              to={`${USER_ADMIN_ROOT_PATH}/${VIEW_PATHS_USER_ADMIN.USER_USAGE}`}
              className={styles.Button}
              data-hc-name={`${dataHcName}-usage-link`}
            >
              <UserMenuButton
                dataHcName={`${dataHcName}-usage`}
                icon={<UsageIcon dataHcName={`${dataHcName}-usage-icon`} />}
                label="Usage"
                description="View the amount of Property Explorer reports pulled this month."
              />
            </Link>
          </UserMenuButtons>
          {buttonGroups?.map((buttonGroup, g) => {
            return (
              <UserMenuButtons
                dataHcName={`${dataHcName}-app-group-${g}`}
                key={`group-${g}`}
              >
                {buttonGroup.map((button, b) => {
                  return (
                    <UserMenuButton
                      {...button}
                      key={`group-${g}-button-${b}`}
                    />
                  );
                })}
              </UserMenuButtons>
            );
          })}
          {isOrgAdmin && (
            <UserMenuButtons dataHcName={`${dataHcName}-org-buttons`}>
              <Link
                to={ORG_ADMIN_ROOT_PATH}
                className={styles.Button}
                data-hc-name={`${dataHcName}-org-admin-link`}
              >
                <UserMenuButton
                  dataHcName={`${dataHcName}-org-admin`}
                  icon={
                    <OrgSettingsIcon
                      dataHcName={`${dataHcName}-org-admin-icon`}
                    />
                  }
                  label="Organization Settings"
                  description="Manage your organization's users, permissions, and preferences."
                />
              </Link>
            </UserMenuButtons>
          )}
          <UserMenuButtons dataHcName={`${dataHcName}-user-buttons`}>
            <Link
              to={`${USER_ADMIN_ROOT_PATH}/${VIEW_PATHS_USER_ADMIN.USER_CONTACT_INFO}`}
              className={styles.Button}
              data-hc-name={`${dataHcName}-user-admin-link`}
            >
              <UserMenuButton
                dataHcName={`${dataHcName}-user-admin`}
                icon={
                  <ProfileIcon dataHcName={`${dataHcName}-user-admin-icon`} />
                }
                label="Edit Profile"
                description="Manage your personal information or change your password."
              />
            </Link>

            <a
              href={SUPPORT_URL}
              data-hc-name={`${dataHcName}-support-link`}
              className={styles.Button}
              target="_blank"
              rel="noreferrer"
            >
              <UserMenuButton
                dataHcName={`${dataHcName}-support`}
                icon={<SupportIcon dataHcName={`${dataHcName}-support-icon`} />}
                label="Support"
                description="View support articles or get in touch."
              />
            </a>
            <UserMenuLogoutButton
              dataHcName={`${dataHcName}-logout`}
              onLogout={onLogout}
            />
          </UserMenuButtons>
        </>
      }
    />
  );
};
