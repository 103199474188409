import React, { ReactNode } from 'react';

import { UserAlert } from '@hcs/design-system';

import styles from './SelfServiceConfirmAccount.module.css';

interface SelfServiceConfirmAccountProps {
  confirmationText: ReactNode;
}
const dataHcName = 'self-service-confirm-account';
export const SelfServiceConfirmAccount = ({
  confirmationText,
}: SelfServiceConfirmAccountProps) => {
  return (
    <div className={styles.Success} data-hc-name={dataHcName}>
      <UserAlert
        type="success"
        dataHcName={`${dataHcName}-success`}
        text={'Check Your Email'}
        className={styles.UserAlert}
      />
      <p>{confirmationText}</p>
    </div>
  );
};
