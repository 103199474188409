import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useTrackEngagementEvent } from '@hcs/engagement-tracking';
import { APP_CONFIG_PEXP } from '@hcs/hc-products';
import { SignUpPayload } from '@hcs/types';

import { SelfServiceApi } from '../api';

// Export hook-specific types here rather than in types file
export type UseSignUpOptions = UseMutationOptions<
  void,
  AxiosError<{ status: string }>,
  SignUpPayload,
  void
>;

const EVENT_NAME_SIGN_UP = 'user-sign-up-self-service';

export const useSelfServiceSignUp = (options?: UseSignUpOptions) => {
  const trackEngagementEventMutation = useTrackEngagementEvent();
  return useMutation<void, AxiosError<{ status: string }>, SignUpPayload, void>(
    async (signUpPayload) => {
      const payload: SignUpPayload = {
        application: APP_CONFIG_PEXP.appSlug,
        ...signUpPayload,
      };
      return await SelfServiceApi.signUp(payload);
    },
    {
      ...options,
      onError(error, variables) {
        options?.onError?.(error, variables);
        const eventName = `${EVENT_NAME_SIGN_UP}-error`;
        // Track a distinct engagement event
        trackEngagementEventMutation.mutate({
          eventName: eventName,
          eventData: {
            self_service_signup_error: error.response?.data.status,
          },
        });
      },
      onSuccess(data, variables) {
        options?.onSuccess?.(data, variables);
        // Track a distinct engagement event
        trackEngagementEventMutation.mutate({
          eventName: `${EVENT_NAME_SIGN_UP}-success`,
          // Passing these fields directly since the useAccount hook in the scope where trackEngagementEventMutation
          // is defined won't have the updated account data yet
          email: variables.email,
          eventData: {
            subscriptionType: 'self-service',
          },
        });
      },
    }
  );
};
