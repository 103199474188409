import React from 'react';

import { TermsAndPrivacyLinks } from '@hcs/auth';
import { SelfServiceSignUp } from '@hcs/self-service';

import { useNavigateToLogin } from '../../../access/hooks/useNavigateToLogin';
import { useNavigateToMiSelfServiceSetup } from '../../hooks/useNavigateToMiSelfServiceSetup';

import styles from './MiSelfServiceSignUpPage.module.css';

export const MiSelfServiceSignUpPage = () => {
  const navigateToLogin = useNavigateToLogin();
  const navigateToSetup = useNavigateToMiSelfServiceSetup();
  return (
    <>
      <SelfServiceSignUp
        className={styles.SignUp}
        navigateToLogin={navigateToLogin}
        onSuccess={navigateToSetup}
      />
      <TermsAndPrivacyLinks />
    </>
  );
};
