import React, { useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';

import { useIsLoggedIn, useUserHmacHash } from '@hcs/auth';

import { useIsHelpChatEnabled } from '../../hooks';

const HelpChatUserActiveIntercom = () => {
  const [wasUserLoggedIn, setWasUserLoggedIn] = useState(false);
  const { data: account } = useIsLoggedIn();
  const { shutdown, update, boot } = useIntercom();
  const {
    user,
    currentOrganization,
    accessibleApplications,
    subscriptionType,
  } = account;
  const { data: hmacData } = useUserHmacHash();

  const { hmac } = hmacData || {};

  // update user when account loads
  useEffect(() => {
    if (user && hmac) {
      update({
        email: user.email,
        userId: user.id.toString(),
        userHash: hmac,
        name:
          user.firstName && user.lastName
            ? `${user.firstName} ${user.lastName}`
            : undefined,
        company: currentOrganization
          ? {
              companyId: currentOrganization.id.toString(),
              name: currentOrganization.slug,
              plan: accessibleApplications
                ? accessibleApplications
                    .map((app) => app.displayName)
                    .sort()
                    .join(', ')
                : undefined,
              customAttributes: {
                orgName: currentOrganization.name,
                salesforceAccountId: currentOrganization.salesforceAccountId,
                subscriptionType:
                  subscriptionType === 'self-service'
                    ? 'Self-Service'
                    : 'Enterprise',
              },
            }
          : undefined,
        createdAt: user.createdOn,
      });
      setWasUserLoggedIn(true);
    }
  }, [
    user,
    currentOrganization,
    accessibleApplications,
    subscriptionType,
    hmac,
    update,
  ]);

  // clear user when account is cleared
  useEffect(() => {
    if (!user?.email && wasUserLoggedIn) {
      shutdown();
      // re-boot so help chat is available on login / if the user logs back in
      boot();
      setWasUserLoggedIn(false);
    }
  }, [user, wasUserLoggedIn, shutdown, boot]);

  return null;
};

export const HelpChatUser = () => {
  const isHelpChatEnabled = useIsHelpChatEnabled();
  if (isHelpChatEnabled) {
    return <HelpChatUserActiveIntercom />;
  }
  return null;
};
