import React from 'react';

import { useCurrentOrg } from '@hcs/auth';
import { SubNav, SubNavItemConfig } from '@hcs/design-system';
import { useExperienceFlag } from '@hcs/experience-flags';
import { VIEW_PATHS_ORG_ADMIN } from '@hcs/hc-products';
import { useOmSftpAccess } from '@hcs/order-manager';

const dataHcName = 'organization-left-nav';
export const OrgAdminNav = () => {
  const { data: hasOMAccess } = useOmSftpAccess();
  const experienceFlagPexpSettings = useExperienceFlag('PEXP_REPORT_API');
  const ssoFlag = useExperienceFlag('CLIENT_SSO_RELEASE');
  // const capabilititySystemEnabled = useExperienceFlag('CAPABILITY_SYSTEM');
  const { data: org } = useCurrentOrg();
  const subNavItemsConfig: (SubNavItemConfig | false | undefined | null)[] = [
    {
      label: 'Account',
      dataHcName: 'account-header',
      navigateTo: VIEW_PATHS_ORG_ADMIN.ORG_USAGE,
      children: [
        {
          label: 'Usage',
          navigateTo: VIEW_PATHS_ORG_ADMIN.ORG_USAGE,
          dataHcName: 'usage',
        },
        // capabilititySystemEnabled && {
        //   label: 'Usage Queries',
        //   navigateTo: VIEW_PATHS_ORG_ADMIN.ORG_USAGE_QUERIES,
        //   dataHcName: 'usage-queries',
        // },
        !!(org?.zAccountId || org?.zAccountNumber || org?.m3TerAccountId) && {
          label: 'Billing',
          navigateTo: VIEW_PATHS_ORG_ADMIN.ORG_BILLING,
          dataHcName: 'billing',
        },
        ssoFlag && {
          label: 'Single Sign On',
          dataHcName: 'single-sign-on',
          navigateTo: VIEW_PATHS_ORG_ADMIN.ORG_SINGLE_SIGN_ON,
        },
      ],
    },
    {
      label: 'Team',
      dataHcName: 'team-header',
      navigateTo: VIEW_PATHS_ORG_ADMIN.ORG_USERS,
      children: [
        {
          label: 'Users',
          navigateTo: VIEW_PATHS_ORG_ADMIN.ORG_USERS,
          dataHcName: 'users',
        },
      ],
    },
    experienceFlagPexpSettings && {
      label: 'PEXP',
      dataHcName: 'pexp',
      navigateTo: VIEW_PATHS_ORG_ADMIN.ORG_REPORT_PREFERENCES,
      children: [
        {
          label: 'Report Settings',
          navigateTo: VIEW_PATHS_ORG_ADMIN.ORG_REPORT_PREFERENCES,
          dataHcName: 'report-preferences',
        },
      ],
    },
    hasOMAccess && {
      label: 'Order Manager',
      dataHcName: 'order-manager-header',
      navigateTo: VIEW_PATHS_ORG_ADMIN.ORG_OM_SFTP,
      children: [
        {
          label: 'SFTP',
          navigateTo: VIEW_PATHS_ORG_ADMIN.ORG_OM_SFTP,
          dataHcName: 'sftp',
        },
      ],
    },
  ];

  return (
    <SubNav
      dataHcName={`${dataHcName}-sub-nav`}
      subNavItemsConfig={subNavItemsConfig}
    />
  );
};
