import React from 'react';
import { Navigate } from 'react-router';
import { RouteObject } from 'react-router';
import axios from 'axios';

import { AppConfig } from '@hcs/types';
import { URL_PARAM_REDIRECT } from '@hcs/webapps';

export const urlSearchParamsToObject = (urlParams: URLSearchParams) => {
  const result: Record<string, string> = {};
  urlParams.forEach((value, key) => {
    result[key] = value;
  });
  return result;
};

export const objectToUrlSearchParamString = (
  params: Record<string | number, unknown>
): string => {
  return axios.getUri({ url: '', params });
};

export const createRedirectQuerystring = (): string => {
  return `?${URL_PARAM_REDIRECT}=${
    window.location.pathname
  }${encodeURIComponent(window.location.search)}`;
};

export const getRootPath = (): AppConfig['rootPath'] => {
  return `/${window.location.pathname.split('/')[1]}`;
};

export const build404RedirectRoute = (rootPath: string) => ({
  path: `${rootPath}/*`,
  element: <Navigate to={rootPath} replace />,
});

export const addErrorElementToRoutes = (
  errorElement: React.ReactNode,
  routes: RouteObject[]
): RouteObject[] => {
  return routes.map((route) => {
    return {
      errorElement,
      ...route,
    };
  });
};
