import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { LayoutContent } from '@hcs/design-system';
import { LayoutGlobalHeaderChildren } from '@hcs/design-system';
import { AppAccessDevTool } from '@hcs/dev-tools';
import { APP_CONFIG_ORG_ADMIN, VIEW_PATHS_ORG_ADMIN } from '@hcs/hc-products';
import { RedirectWithSearchParams } from '@hcs/routing';
import { build404RedirectRoute } from '@hcs/utils';

import { NonAdminPageRedirect } from '../../../auth-redirects/NonAdminPageRedirect';
import { NonOmSftpAccessPageRedirect } from '../../../auth-redirects/NonOmSftpAccessPageRedirect';
import { PrivatePageRedirect } from '../../../auth-redirects/PrivatePageRedirect';
import { AdminNav } from '../../../navigation/AdminNav/AdminNav';
import { SolutionsPlatformGlobalHeader } from '../../../navigation/SolutionsPlatformGlobalHeader';
import { OrgAdminNav } from '../navigation/OrgAdminNav';
import {
  OrgBillingPage,
  OrgOmSftpPage,
  OrgReportPreferencesPage,
  OrgSingleSignOnPage,
  OrgUsagePage,
  // OrgUsageQueriesPage,
  OrgUsersPage,
} from '../pages';

const ORG_ADMIN_ROOT_PATH = APP_CONFIG_ORG_ADMIN.rootPath;
export const routes = (): RouteObject[] => [
  {
    path: ORG_ADMIN_ROOT_PATH,
    element: (
      <PrivatePageRedirect>
        <NonAdminPageRedirect>
          <LayoutGlobalHeaderChildren
            header={<SolutionsPlatformGlobalHeader />}
          >
            <AdminNav />
            <OrgAdminNav />
            <LayoutContent />
            <AppAccessDevTool />
          </LayoutGlobalHeaderChildren>
        </NonAdminPageRedirect>
      </PrivatePageRedirect>
    ),
    children: [
      {
        path: VIEW_PATHS_ORG_ADMIN.ORG_USAGE,
        element: <OrgUsagePage />,
      },
      // {
      //   path: VIEW_PATHS_ORG_ADMIN.ORG_USAGE_QUERIES,
      //   element: <OrgUsageQueriesPage />,
      // },
      {
        path: VIEW_PATHS_ORG_ADMIN.ORG_BILLING,
        element: <OrgBillingPage />,
      },
      {
        path: VIEW_PATHS_ORG_ADMIN.ORG_USERS,
        element: <OrgUsersPage />,
      },
      {
        path: VIEW_PATHS_ORG_ADMIN.ORG_OM_SFTP,
        element: (
          <NonOmSftpAccessPageRedirect>
            <OrgOmSftpPage />
          </NonOmSftpAccessPageRedirect>
        ),
      },
      {
        path: VIEW_PATHS_ORG_ADMIN.ORG_REPORT_PREFERENCES,
        element: <OrgReportPreferencesPage />,
      },
      {
        path: VIEW_PATHS_ORG_ADMIN.ORG_SINGLE_SIGN_ON,
        element: <OrgSingleSignOnPage />,
      },
      {
        path: ORG_ADMIN_ROOT_PATH,
        element: <Navigate to={VIEW_PATHS_ORG_ADMIN.ORG_USAGE} replace />,
      },
      build404RedirectRoute(ORG_ADMIN_ROOT_PATH),
    ],
  },
  {
    path: VIEW_PATHS_ORG_ADMIN.LEGACY_USAGE_AND_BILLING,
    element: (
      <RedirectWithSearchParams
        path={`${ORG_ADMIN_ROOT_PATH}/${VIEW_PATHS_ORG_ADMIN.ORG_USAGE}`}
      />
    ),
  },
  {
    path: VIEW_PATHS_ORG_ADMIN.LEGACY_ORG_SETTINGS,
    element: (
      <RedirectWithSearchParams
        path={`${ORG_ADMIN_ROOT_PATH}/${VIEW_PATHS_ORG_ADMIN.ORG_USERS}`}
      />
    ),
  },
];
