import React from 'react';

import { TermsAndPrivacyLinks } from '@hcs/auth';
import { Card } from '@hcs/design-system';
import { SelfServiceConfirmAccount } from '@hcs/self-service';

import styles from './MiSelfServiceSetupPage.module.css';

const dataHcName = 'mi-self-setup-page';
export const MiSelfServiceSetupPage = () => {
  return (
    <>
      <Card dataHcName={dataHcName} className={styles.Card}>
        <SelfServiceConfirmAccount
          confirmationText="A confirmation email was sent to your 
            email address. Please confirm your email to access Market Insights."
        />
      </Card>
      <TermsAndPrivacyLinks />
    </>
  );
};
