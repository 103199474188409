/** This file was generated by generateExportBuckets.js */
import { PropertyStateFieldConfig, PropertyStateFields } from '@hcs/types';

import { AGE_CONFIG } from './age';
import { APN_CONFIG } from './apn';
import { ASSOCIATION_CONFIG } from './association';
import { ASSOCIATION_AMENITIES_CONFIG } from './associationAmenities';
import { ASSOCIATION_FEE_CONFIG } from './associationFee';
import { ASSOCIATION_FEE_FREQUENCY_CONFIG } from './associationFeeFrequency';
import { ASSOCIATION_FEE_INCLUDES_CONFIG } from './associationFeeIncludes';
import { ASSOCIATION_NAME_CONFIG } from './associationName';
import { BASEMENT_CONFIG } from './basement';
import { BASEMENT_HAS_CONFIG } from './basementHas';
import { BATHROOMS_CONFIG } from './bathrooms';
import { BEDROOMS_CONFIG } from './bedrooms';
import { CONDITION_CONFIG } from './condition';
import { COOLING_CONFIG } from './cooling';
import { COUNTY_CONFIG } from './county';
import { CURRENT_DISTRESSED_CONFIG } from './currentDistressed';
import { CURRENT_FLIP_CONFIG } from './currentFlip';
import { CURRENT_LIST_DATE_CONFIG } from './currentListDate';
import { CURRENT_LIST_DATE_RENTAL_CONFIG } from './currentListDateRental';
import { CURRENT_LISTING_PRICE_CONFIG } from './currentListingPrice';
import { CURRENT_LISTING_PRICE_PER_SQFT_CONFIG } from './currentListingPricePerSqFt';
import { LIST_PRICE_PER_SQFT_RENTAL_CONFIG } from './currentListingPricePerSqFtRental';
import { CURRENT_LISTING_PRICE_RENTAL_CONFIG } from './currentListingPriceRental';
import { CURRENT_PRICE_CONFIG } from './currentPrice';
import { CURRENT_PRICE_PER_SQFT_CONFIG } from './currentPricePerSqFt';
import { CURRENT_PRICE_PER_SQFT_RENTAL_CONFIG } from './currentPricePerSqFtRental';
import { CURRENT_PRICE_RENTAL_CONFIG } from './currentPriceRental';
import { CURRENT_STATUS_CONFIG } from './currentStatus';
import { CURRENT_STATUS_DATE_CONFIG } from './currentStatusDate';
import { CURRENT_STATUS_DATE_RENTAL_CONFIG } from './currentStatusDateRental';
import { CURRENT_STATUS_RENTAL_CONFIG } from './currentStatusRental';
import { CURRENT_VALUE_CONFIG } from './currentValue';
import { CURRENT_VALUE_PER_SQFT_CONFIG } from './currentValuePerSqft';
import { CURRENT_VALUE_PER_SQFT_RENTAL_CONFIG } from './currentValuePerSqftRental';
import { CURRENT_VALUE_RENTAL_CONFIG } from './currentValueRental';
import { DAYS_ON_MARKET_ACTIVE_CONFIG } from './daysOnMarketActive';
import { DAYS_ON_MARKET_CUMULATIVE_CONFIG } from './daysOnMarketCumulative';
import { DAYS_ON_MARKET_MLS_CONFIG } from './daysOnMarketMls';
import { DAYS_ON_MARKET_MLS_RENTAL_CONFIG } from './daysOnMarketMlsRental';
import { GARAGE_SPACES_CONFIG } from './garageSpaces';
import { GARAGE_TYPE_CONFIG } from './garageType';
import { LIVING_AREA_CONFIG } from './grossLivingArea';
import { HEATING_CONFIG } from './heating';
import { LAND_VALUE_CONFIG } from './landValue';
import { LAST_CLOSE_DATE_CONFIG } from './lastCloseDate';
import { LAST_CLOSE_DATE_RENTAL_CONFIG } from './lastCloseDateRental';
import { LAST_CLOSE_PRICE_CONFIG } from './lastClosePrice';
import { LAST_CLOSE_PRICE_PER_SQFT_CONFIG } from './lastClosePricePerSqFt';
import { LAST_CLOSE_PRICE_PER_SQFT_RENTAL_CONFIG } from './lastClosePricePerSqFtRental';
import { LAST_CLOSE_PRICE_RENTAL_CONFIG } from './lastClosePriceRental';
import { LISTING_AGENT_OFFICE_CONFIG } from './listingAgentOffice';
import { LISTING_AGENT_OFFICE_RENTAL_CONFIG } from './listingAgentOfficeRental';
import { LOT_SIZE_CONFIG } from './lotSize';
import { OWNER_OCCUPIED_CONFIG } from './ownerOccupied';
import { PARKING_CONFIG } from './parking';
import { PARKING_TOTAL_CONFIG } from './parkingTotal';
import { POOL_CONFIG } from './pool';
import { PROPERTY_TYPE_CONFIG } from './propertyType';
import { RENTAL_YIELD_CONFIG } from './rentalYield';
import { ROOMS_TOTAL_CONFIG } from './roomsTotal';
import { SALE_TYPE_CONFIG } from './saleType';
import { STORIES_CONFIG } from './stories';
import { SUBDIVISION_NAME_CONFIG } from './subdivisionName';
import { TAX_AMOUNT_CONFIG } from './taxAmount';
import { TAX_YEAR_CONFIG } from './taxYear';
import { YEAR_BUILT_CONFIG } from './yearBuilt';
import { ZONING_CONFIG } from './zoning';
export * from './address';
export * from './apn';
export * from './association';
export * from './associationAmenities';
export * from './associationFee';
export * from './associationFeeFrequency';
export * from './associationFeeIncludes';
export * from './associationName';
export * from './basement';
export * from './basementHas';
export * from './bathrooms';
export * from './bedrooms';
export * from './condition';
export * from './cooling';
export * from './county';
export * from './currentDistressed';
export * from './currentFlip';
export * from './currentListDate';
export * from './currentListDateRental';
export * from './currentListingPrice';
export * from './currentListingPricePerSqFt';
export * from './currentListingPricePerSqFtRental';
export * from './currentListingPriceRental';
export * from './currentPrice';
export * from './currentPricePerSqFt';
export * from './currentPricePerSqFtRental';
export * from './currentPriceRental';
export * from './currentStatus';
export * from './currentStatusDate';
export * from './currentStatusDateRental';
export * from './currentStatusRental';
export * from './currentValue';
export * from './currentValuePerSqft';
export * from './currentValuePerSqftRental';
export * from './currentValueRental';
export * from './daysOnMarketActive';
export * from './daysOnMarketCumulative';
export * from './daysOnMarketMls';
export * from './daysOnMarketMlsRental';
export * from './garageSpaces';
export * from './garageType';
export * from './grossLivingArea';
export * from './heating';
export * from './lastCloseDate';
export * from './lastCloseDateRental';
export * from './lastClosePrice';
export * from './lastClosePricePerSqFt';
export * from './lastClosePricePerSqFtRental';
export * from './lastClosePriceRental';
export * from './listingAgentOffice';
export * from './listingAgentOfficeRental';
export * from './lotSize';
export * from './ownerOccupied';
export * from './parking';
export * from './parkingTotal';
export * from './pool';
export * from './propertyType';
export * from './rentalYield';
export * from './roomsTotal';
export * from './stories';
export * from './subdivisionName';
export * from './taxAmount';
export * from './taxYear';
export * from './yearBuilt';
export * from './zoning';

// Please keep alphabetical
export const PROPERTY_STATE_FIELD_CONFIGS: {
  [F in PropertyStateFields]: PropertyStateFieldConfig<F>;
} = {
  [PropertyStateFields.daysOnMarketActive]: DAYS_ON_MARKET_ACTIVE_CONFIG,
  [PropertyStateFields.age]: AGE_CONFIG,
  [PropertyStateFields.apn]: APN_CONFIG,
  [PropertyStateFields.basement]: BASEMENT_CONFIG,
  [PropertyStateFields.basementHas]: BASEMENT_HAS_CONFIG,
  [PropertyStateFields.bathrooms]: BATHROOMS_CONFIG,
  [PropertyStateFields.bedrooms]: BEDROOMS_CONFIG,
  [PropertyStateFields.condition]: CONDITION_CONFIG,
  [PropertyStateFields.cooling]: COOLING_CONFIG,
  [PropertyStateFields.county]: COUNTY_CONFIG,
  [PropertyStateFields.heating]: HEATING_CONFIG,
  [PropertyStateFields.daysOnMarketMls]: DAYS_ON_MARKET_MLS_CONFIG,
  [PropertyStateFields.daysOnMarketMlsRental]: DAYS_ON_MARKET_MLS_RENTAL_CONFIG,
  [PropertyStateFields.daysOnMarketCumulative]:
    DAYS_ON_MARKET_CUMULATIVE_CONFIG,
  [PropertyStateFields.currentValue]: CURRENT_VALUE_CONFIG,
  [PropertyStateFields.currentValuePerSqFt]: CURRENT_VALUE_PER_SQFT_CONFIG,
  [PropertyStateFields.currentValuePerSqFtRental]:
    CURRENT_VALUE_PER_SQFT_RENTAL_CONFIG,
  [PropertyStateFields.currentValueRental]: CURRENT_VALUE_RENTAL_CONFIG,
  [PropertyStateFields.currentDistressed]: CURRENT_DISTRESSED_CONFIG,
  [PropertyStateFields.currentFlip]: CURRENT_FLIP_CONFIG,
  [PropertyStateFields.parking]: PARKING_CONFIG,
  [PropertyStateFields.parkingTotal]: PARKING_TOTAL_CONFIG,
  [PropertyStateFields.garageSpaces]: GARAGE_SPACES_CONFIG,
  [PropertyStateFields.garageType]: GARAGE_TYPE_CONFIG,
  [PropertyStateFields.livingArea]: LIVING_AREA_CONFIG,
  [PropertyStateFields.roomsTotal]: ROOMS_TOTAL_CONFIG,
  [PropertyStateFields.association]: ASSOCIATION_CONFIG,
  [PropertyStateFields.associationAmenities]: ASSOCIATION_AMENITIES_CONFIG,
  [PropertyStateFields.associationFee]: ASSOCIATION_FEE_CONFIG,
  [PropertyStateFields.associationFeeFrequency]:
    ASSOCIATION_FEE_FREQUENCY_CONFIG,
  [PropertyStateFields.associationFeeIncludes]: ASSOCIATION_FEE_INCLUDES_CONFIG,
  [PropertyStateFields.associationName]: ASSOCIATION_NAME_CONFIG,
  [PropertyStateFields.listingAgentOffice]: LISTING_AGENT_OFFICE_CONFIG,
  [PropertyStateFields.listingAgentOfficeRental]:
    LISTING_AGENT_OFFICE_RENTAL_CONFIG,
  [PropertyStateFields.currentListDate]: CURRENT_LIST_DATE_CONFIG,
  [PropertyStateFields.currentListDateRental]: CURRENT_LIST_DATE_RENTAL_CONFIG,
  [PropertyStateFields.currentListingPrice]: CURRENT_LISTING_PRICE_CONFIG,
  [PropertyStateFields.currentListingPriceRental]:
    CURRENT_LISTING_PRICE_RENTAL_CONFIG,
  [PropertyStateFields.currentListingPricePerSqFt]:
    CURRENT_LISTING_PRICE_PER_SQFT_CONFIG,
  [PropertyStateFields.currentListingPricePerSqFtRental]:
    LIST_PRICE_PER_SQFT_RENTAL_CONFIG,
  [PropertyStateFields.currentPrice]: CURRENT_PRICE_CONFIG,
  [PropertyStateFields.currentStatusDate]: CURRENT_STATUS_DATE_CONFIG,
  [PropertyStateFields.currentPricePerSqFt]: CURRENT_PRICE_PER_SQFT_CONFIG,
  [PropertyStateFields.currentPriceRental]: CURRENT_PRICE_RENTAL_CONFIG,
  [PropertyStateFields.currentStatusDateRental]:
    CURRENT_STATUS_DATE_RENTAL_CONFIG,
  [PropertyStateFields.currentPricePerSqFtRental]:
    CURRENT_PRICE_PER_SQFT_RENTAL_CONFIG,
  [PropertyStateFields.ownerOccupied]: OWNER_OCCUPIED_CONFIG,
  [PropertyStateFields.currentStatus]: CURRENT_STATUS_CONFIG,
  [PropertyStateFields.currentStatusRental]: CURRENT_STATUS_RENTAL_CONFIG,
  [PropertyStateFields.propertyType]: PROPERTY_TYPE_CONFIG,
  [PropertyStateFields.pool]: POOL_CONFIG,
  [PropertyStateFields.landValue]: LAND_VALUE_CONFIG,
  [PropertyStateFields.lastCloseDate]: LAST_CLOSE_DATE_CONFIG,
  [PropertyStateFields.lastCloseDateRental]: LAST_CLOSE_DATE_RENTAL_CONFIG,
  [PropertyStateFields.lastClosePrice]: LAST_CLOSE_PRICE_CONFIG,
  [PropertyStateFields.lastClosePriceRental]: LAST_CLOSE_PRICE_RENTAL_CONFIG,
  [PropertyStateFields.lastClosePricePerSqFt]: LAST_CLOSE_PRICE_PER_SQFT_CONFIG,
  [PropertyStateFields.lastClosePricePerSqFtRental]:
    LAST_CLOSE_PRICE_PER_SQFT_RENTAL_CONFIG,
  [PropertyStateFields.lotSize]: LOT_SIZE_CONFIG,
  [PropertyStateFields.rentalYield]: RENTAL_YIELD_CONFIG,
  [PropertyStateFields.saleType]: SALE_TYPE_CONFIG,
  [PropertyStateFields.stories]: STORIES_CONFIG,
  [PropertyStateFields.subdivisionName]: SUBDIVISION_NAME_CONFIG,
  [PropertyStateFields.taxAmount]: TAX_AMOUNT_CONFIG,
  [PropertyStateFields.taxYear]: TAX_YEAR_CONFIG,
  [PropertyStateFields.yearBuilt]: YEAR_BUILT_CONFIG,
  [PropertyStateFields.zoning]: ZONING_CONFIG,
};
