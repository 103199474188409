import { useNavigate } from 'react-router';

import {
  APP_CONFIG_MARKET_INSIGHTS,
  VIEW_PATHS_MARKET_INSIGHTS,
} from '@hcs/hc-products';

export const useNavigateToMiSelfServiceSetup = () => {
  const navigate = useNavigate();
  return () => {
    navigate(
      `${APP_CONFIG_MARKET_INSIGHTS.rootPath}/${VIEW_PATHS_MARKET_INSIGHTS.SELF_SERVICE_SETUP}`
    );
  };
};
