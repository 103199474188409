import { combineReducers } from 'redux';

import { engagementTrackingDevToolSlice } from './engagementTrackingDevTool.slice';
import { engagementTrackingEventDataSlice } from './eventData.slice';
import { engagementTrackingGlobalDataSlice } from './globalData.slice';
import { engagementTrackingMeaningfulEventsSlice } from './meaningfulEvents.slice';

export const ENGAGEMENT_TRACKING_REDUCER_KEY = 'engagementTracking';
export const engagementTrackingReducer = combineReducers({
  [engagementTrackingEventDataSlice.name]:
    engagementTrackingEventDataSlice.reducer,
  [engagementTrackingDevToolSlice.name]: engagementTrackingDevToolSlice.reducer,
  [engagementTrackingMeaningfulEventsSlice.name]:
    engagementTrackingMeaningfulEventsSlice.reducer,
  [engagementTrackingGlobalDataSlice.name]:
    engagementTrackingGlobalDataSlice.reducer,
});
