import React, { useMemo } from 'react';

import {
  ChildItemConfig,
  SubNav,
  SubNavItemConfig,
  SubNavProps,
} from '@hcs/design-system';
import { AppSlugs } from '@hcs/types';

import { useAccount } from '../../hooks/useAccount';
import { useBundleAppConfigs } from '../../hooks/useBundleAppConfigs';
import { checkAppAccess } from '../../utils';

export interface AppAccessSubNavChildConfig extends ChildItemConfig {
  appSlug?: AppSlugs;
}
interface AppAccessSubNavItemConfig extends Omit<SubNavItemConfig, 'children'> {
  children: AppAccessSubNavChildConfig[];
}

export interface AppAccessSubNavProps
  extends Omit<SubNavProps, 'subNavItemsConfig'> {
  subNavItemsConfig: AppAccessSubNavItemConfig[];
}
export const AppAccessSubNav = ({
  subNavItemsConfig: subNavItemsConfigProp,
  ...props
}: AppAccessSubNavProps) => {
  const appConfigs = useBundleAppConfigs();
  const { data: account } = useAccount();
  const subNavItemsConfig = useMemo(() => {
    const itemConfigs: SubNavItemConfig[] = [];
    subNavItemsConfigProp.forEach((itemConfig) => {
      const childConfigs: ChildItemConfig[] = [];
      itemConfig.children.forEach(({ appSlug, ...childConfig }) => {
        if (appSlug) {
          const appConfig = appConfigs?.find((a) => a.appSlug === appSlug);
          if (account && appConfig && checkAppAccess(account, appConfig)) {
            childConfigs.push(childConfig);
          }
        } else {
          childConfigs.push(childConfig);
        }
      });
      if (childConfigs.length) {
        itemConfigs.push({
          ...itemConfig,
          children: childConfigs,
        });
      }
    });
    return itemConfigs;
  }, [account, subNavItemsConfigProp, appConfigs]);
  return <SubNav {...props} subNavItemsConfig={subNavItemsConfig} />;
};
