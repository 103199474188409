import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { resetAccountQuery } from '@hcs/http-clients';
import { resetOnLogoutAction } from '@hcs/utils';
import { URL_PARAM_NO_REDIRECT } from '@hcs/webapps';

import { AuthApi } from '../api';
import { setNewSessionId } from '../utils';

// Export hook-specific types here rather than in types file
export type UseLogoutOptions = UseMutationOptions<void, AxiosError, void, void>;

export const useLogout = (options?: UseLogoutOptions) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  return useMutation<void, AxiosError, void, void>(
    async () => {
      setNewSessionId();
      searchParams.set(URL_PARAM_NO_REDIRECT, 'true');
      setSearchParams(searchParams);
      await AuthApi.logout();
    },
    {
      ...options,
      onError(error, variables) {
        const responseStatus = error.response?.status;
        if (responseStatus === 401) {
          resetAccountQuery();
        }
        options?.onError?.(error, variables);
      },
      onSuccess(response, variables) {
        // reset redux state
        dispatch(resetOnLogoutAction());
        resetAccountQuery();
        options?.onSuccess?.(response, variables);
      },
    }
  );
};
