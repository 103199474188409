import { useQuery } from '@tanstack/react-query';

import { APP_CONFIG_PEXP } from '@hcs/hc-products';

import { SelfServiceApi } from '../api';

export const QUERY_KEY_SELF_SERVICE_COUNTS = 'self-service-counts';
export const useSelfServiceCounts = (config?: { enabled: boolean }) => {
  return useQuery(
    [QUERY_KEY_SELF_SERVICE_COUNTS],
    async () => {
      const accessData = await SelfServiceApi.fetchSelfServiceAccess(
        APP_CONFIG_PEXP.appSlug
      );
      return accessData.selfService;
    },
    {
      enabled: config !== undefined ? config.enabled : true,
      refetchOnMount: 'always',
    }
  );
};
