import { CanaryAIIcon } from '@hcs/design-system';
import { AppConfig, AppSlugs, CapabilityApplications } from '@hcs/types';

import CanaryAIHeroImage from '../assets/product-images/canary-ai-product-hero.png';

export const APP_CONFIG_CANARYAI: AppConfig = {
  name: 'CanaryAI',
  appSlug: AppSlugs.CanaryAI,
  applicationKey: CapabilityApplications.CanaryAI,
  rootPath: `/canaryai`,
  Icon: CanaryAIIcon,
  descriptionLong: 'Make housing data conversational with CanaryAI.',
  descriptionShort:
    'Make housing data conversational with CanaryAI. Convert complex data into easy-to-understand conversations.',
  marketingImage: CanaryAIHeroImage,
};
