import { useNavigate } from 'react-router';

import { VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM } from '@hcs/hc-products';

export const useNavigateToLogin = (): VoidFunction => {
  const navigate = useNavigate();
  return () => {
    navigate(VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.LOGIN);
  };
};
